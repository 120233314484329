import "../Styles/WaitList.css";
import background from "../Images/log-in-background.png";
import logo from "../Images/logoAR.svg";
import vector from "../Images/Vector.svg";
import Branch from "../Images/Branch.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import enLogo from "../Images/enLogo.svg";

import React from "react";

import { BeatLoader } from "react-spinners";
import { waitingList, readyTable, nowTable } from "../api";
import useSpeechSynthesis from "react-speech-kit/dist/useSpeechSynthesis";

function WaitList() {
  const { t, i18n } = useTranslation();
  const { speak, voices } = useSpeechSynthesis();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [className, setClassName] = useState("order hidden");
  const [tableNum, setTableNum] = useState(0);
  const [tableSession, setTableSession] = useState();
  const [orderNum, setOrderNum] = useState(0);
  const [waitList, setWaitList] = useState([]);
  const [servicedTables, setServicedTables] = useState([]);

  const getArabicVoice = () => {
    const arabicVoice = voices.find((v) => v.lang.startsWith("ar"));
    console.log(arabicVoice);
    return arabicVoice;
  };

  useEffect(() => {
    readyTable(token, localStorage.getItem("tableBranchId")).then(
      (response) => {
        if (
          response.data.data !== tableNum &&
          response.data.data.length !== 0
        ) {
          setTableSession(response.data.data);
        }
      }
    );
    let count = 0;

    if (count === 0) {
      waitingList(token).then((response) => {
        setWaitList(response.data.data);
        setServicedTables(response.data.data.servicedTables);
        setLoading(false);
      });
      count++;
    }

    const interval = setInterval(() => {
      waitingList(token).then((response) => {
        setWaitList(response.data.data);
        setServicedTables(response.data.data.servicedTables);
      });

      nowTable(token, localStorage.getItem("tableBranchId")).then(
        (response) => {
          console.log(response.data.data);
          if (
            response.data.data !== tableNum &&
            response.data.data.length !== 0
          ) {
            setTableNum(response.data.data.tableName);
            setOrderNum(response.data.data.orderId);
            setClassName("visible");

            setTimeout(function () {
              console.log("Clicking speak after 3 seconds");
              document.getElementById("speak").click();
            }, 1000);

            setTimeout(function () {
              console.log("Clicking speak after 3 seconds");
              document.getElementById("speak").click();
            }, 1000);

            setText(
              `عميل رقم ${response.data.data.orderId} برجاء التوجه الي جلسة رقم  ${response.data.data.tableName}`
            );
          }
        }
      );

      readyTable(token, localStorage.getItem("tableBranchId")).then(
        (response) => {
          if (
            response.data.data !== tableNum &&
            response.data.data.length !== 0
          ) {
            setTableSession(response.data.data);
            // setClassName("order test");
          }
        }
      );
    }, 10000);

    return () => clearInterval(interval);
  }, [tableNum]); // Add hasSpoken as a dependency

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("language"));
    i18n.changeLanguage(data);
    if (i18n.language === "en") {
      document.getElementById("root").style.direction = "ltr";
    } else {
      document.getElementById("root").style.direction = "rtl";
    }

    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = " الرومانسية كاشير";
    } else {
      document.title = "AlRomansiah Cashier";
    }
  }, [i18n]);

  return (
    <div className="container-fluid">
      {loading ? (
        <div style={{ height: "100vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="row">
          <div
            style={{
              backgroundColor: "#6332350d",
              height: "100vh",
              margin: "0 !important",
              overflow: "hidden",
            }}
            className="col-4"
          >
            <div
              className="orderNumber"
              style={waitingList.length === 0 ? { display: "none" } : {}}
            >
              <div
                style={{ overflow: "hidden" }}
                className="container-fluid p-0"
              >
                <div className="row gy-3 row1" style={{ textAlign: "left" }}>
                  <div className="col-12" style={{ marginTop: "5vh" }}>
                    <button
                      id="speak"
                      onClick={() =>
                        speak({ text: text, voice: getArabicVoice() })
                      }
                    ></button>
                    <p className="titleW">{t("menu.waiting")} </p>
                  </div>

                  <div className="col-12 titles">
                    <p>{t("tables.orderNo")}</p>
                  </div>

                  {waitList.length > 25 ? (
                    // When more than 25 items, show them in two columns
                    <React.Fragment>
                      <div className="col-6">
                        {waitList
                          .slice(0, Math.ceil(waitList.length / 2))
                          .map((data, index) => (
                            <div className="number" key={index}>
                              <p style={{ marginTop: "3vh" }}>{data.orderId}</p>
                            </div>
                          ))}
                      </div>
                      <div className="col-6">
                        {waitList
                          .slice(Math.ceil(waitList.length / 2))
                          .map((data, index) => (
                            <div className="number" key={index}>
                              <p style={{ marginTop: "3vh" }}>{data.orderId}</p>
                            </div>
                          ))}
                      </div>
                    </React.Fragment>
                  ) : (
                    // Original display for 25 or fewer items
                    <div className="col-12">
                      {waitList.map((data, index) => (
                        <div className="number" key={index}>
                          <p style={{ marginTop: "3vh" }}>{data.orderId}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="logo">
              <Link to="/add-reservation">
                <div
                  style={
                    i18n.language === "ar"
                      ? { backgroundImage: `url(${logo})` }
                      : { backgroundImage: `url(${enLogo})` }
                  }
                ></div>
              </Link>
            </div>
            <div
              className=""
              style={{ textAlign: "center", position: "relative" }}
            >
              <div>
                <p style={{ marginTop: "6vh" }} className="mainTitle">
                  إدارة الجلسات
                </p>
                <p className="brnchName">
                  {" "}
                  {i18n.language === "ar"
                    ? localStorage.getItem("tableBranchAr")
                    : localStorage.getItem("tableBranchEn")}{" "}
                </p>

                <div className={className}>
                  <div style={{ marginTop: "4vh" }}>
                    <p className="brnchName">الان في الخدمة</p>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="col-6 titles ">
                        <p> {t("tables.orderNo")}</p>
                      </div>
                      <div className="col-6 titles ">
                        <p> {t("tables.tableNum")}</p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "3vh",
                      }}
                    >
                      <div className="col-6">
                        <div className="number">
                          <p>{orderNum} </p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="number">
                          <p>{tableNum}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div
              className={
                i18n.language === "ar" ? "brnchImg" : "brnchImg brnchImgEn"
              }
              style={{
                backgroundImage: `url(${Branch})`,
                position: "absolute", // Position absolutely
                bottom: 0, // Stick to bottom,
                left: 0,
                width: "100vw",
              }}
            ></div>
          </div>
          <div className="col-4">
            <div
              className=""
              style={{
                backgroundColor: "#6332350d",
                height: "102vh",
                margin: "0 !important",
              }}
            >
              <div className="row gy-3 " style={{ textAlign: "left" }}>
                <div className="" style={{ marginTop: "10vh" }}>
                  <p className="titleW" style={{ textAlign: "center" }}>
                    {t("tables.readyTable")}{" "}
                  </p>
                </div>

                <div className="col-6 titles ">
                  <p> {t("tables.orderNo")}</p>
                </div>
                <div className="col-6 titles ">
                  <p> {t("tables.tableNum")}</p>
                </div>

                {tableSession &&
                  tableSession.map((data, index) => (
                    <React.Fragment key={data.orders[0].id}>
                      <div className="col-6">
                        <div className="number">
                          <p>{data.orders[0].id}</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="number">
                          <p>{data.table.name}</p>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WaitList;
